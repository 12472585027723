<template>
  <div class="page-gray">
    <div class="goodsItem" v-for="(orderItem,index) in orderInfo.inProductionData" :key="index">
      <div class="goodsInfo">
        <img
            :src="orderItem.pic"
            alt=""
        />
        <div class="right">
          <div class="top">
            <div class="name van-multi-ellipsis--l2">
              <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
              <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
              <div style="display: inline;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ orderItem.subProductName }}</div>
            </div>
            <div class="price">
              <span>¥</span>
              <span>{{ orderItem.price }}</span>
            </div>
          </div>
          <div class="tagList" style="display: inline-block">
            <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
            <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
          </div>
          <div style="margin-left: 10px; display: inline-block;">
            <div v-show="orderItem.deliverGoodsTemp === '1'"
                 class="deliveryTime">下单{{orderItem.day}}天内发货</div>
            <div v-show="orderItem.deliverGoodsTemp === '2'"
                 class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
            <div v-show="orderItem.deliverGoodsTemp === '3'"
                 class="deliveryTime"
                 v-html="orderItem.deliverGoodsContent">
            </div>
          </div>

          <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

        </div>
      </div>
    </div>


    <div class="priceInfo">
      共计{{ number }}件商品，商品总价：
      <span class='unit'>¥</span>
      <span class='price'>{{ orderInfo.totalPrice }}</span>
      邮费￥{{ orderInfo.postFee }}
    </div>


    <div class="cell" @click="showReason = true">
      <div class="left">
        退款原因
      </div>
      <div class="right">
        <div class="placeholder" v-if="!reasonId">请选择</div>
        <div class="value" v-else>{{ getReasonText(reasonId) }}</div>
        <img src="./../../assets/images/order/submitOrder/arrow.png" alt="">
      </div>
    </div>
<!--    <div class="cell">
      <div class="left">
        退款总金额：<span>￥{{ orderInfo.payFee }}</span>
      </div>
      <div class="right">
        邮费退款：<span>￥{{ orderInfo.postFee }}</span>
      </div>
    </div>-->

    <div class="cell">
      <div class="left">
        共计：退货&nbsp;<span>{{ number }}</span>&nbsp;件&nbsp;&nbsp;&nbsp;&nbsp;退款&nbsp;<span>{{ orderInfo.payFee }}</span>&nbsp;元
      </div>
      <div class="right">

      </div>
    </div>


    <div class="tips">
      温馨提示:请等待厂家审核，退款将从原路返回，审核后微信支付的即时到账，银行卡支付的24小时内到账，请注意查收。
    </div>


    <van-action-sheet v-model="showReason" title="退款原因" close-icon="close">
      <van-radio-group v-model="reason_stack">
        <van-cell-group>
          <van-cell v-for="item in reasonList" :title="item.text" clickable @click="reason_stack = item.value">
            <template #right-icon>
              <van-radio :name="item.value" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round
                    @click="reasonId = reason_stack; showReason = false">提交
        </van-button>
      </div>
    </van-action-sheet>

    <div style="padding: 10px 18px; bottom: 0;width: 100vw;">
      <van-button type="primary" size="small" block @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateRefund",
  data() {
    return {
      reason_stack: '',
      orderId: '',
      orderInfo: {
        inProductionData: [],
      },
      reasonId: '',
      showReason: false,
      reasonList: [{
        text: '多拍、错拍、不想要',
        value: 1
      }, {
        text: '协商一致退款',
        value: 2
      }, {
        text: '价格太贵了',
        value: 3
      }, {
        text: '发货慢',
        value: 4
      }, {
        text: '其他',
        value: 5
      }]
    }
  },
  computed: {
    number() {
      // 这里还需要合计每个规格的价格吧， 懒得造数据了
      // 理论上直接 统计 selectSkuList 这个二维数组里面的数据就可以了
      let num = 0;
      this.orderInfo.inProductionData.forEach(orderItem => {
        num += orderItem.num;
      })
      return num;
    },
  },
  mounted() {
    this.orderId = this.$route.params.id;
    this.init();
  },
  methods: {
    init() {
      this.$http.get(`/mall/h5_mall_order/queryOrderDetail/${this.orderId}`).then(res => {
        this.orderInfo = res.data;
      });
    },
    getReasonText(val) {
      if (val === '') {
        return {text: ''}
      }
      return this.reasonList.find(e => e.value == val).text;
    },

    submit() {
      if (!this.reasonId) {
        this.$toast('请选择退款原因');
        return;
      }

      this.$http.post(this.baseMallUrl + `/h5_mall_order/h5OrderRefund`, {
        orderId: this.orderId,
        refundRemark: this.getReasonText(this.reasonId),
        refundType: 1,
      }, {
        emulateJSON: true
      }).then(response => {
        response
        this.$toast.success('退款成功')
        this.$router.go(-1);
      })
    }
  }
};
</script>
<style lang="less" scoped>
.ys {
  width: 32px;
  background: linear-gradient(264deg, #f58130, #ef2514);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.xh {
  width: 32px;
  background: linear-gradient(264deg, #4db3ee, #00A0E9);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.deliveryTime {
  margin: 0px 0 5px 0;
  font-size: 14px;
  color: #F3B66A;
}

.right {
  flex: 1;
  width: 0;
  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .name {
      width: 180px;
      font-size: 12px;
      color: #333333;
      line-height: 15px;

    }

    .price {
      line-height: 15px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-weight: bold;
      color: #D53A26;
      span:last-child {
        font-size: 15px;
      }
    }
  }

  .sku {
    background: #eeeeee;
    border-radius: 2px;

    padding: 5px 10px;
    line-height: 10px;
    font-size: 10px;
    color: #666666;

    display: inline-block;
    margin: 5px 0 4px;
  }

  .tagList {
    margin: 6px 0 0;
    font-size: 0;
    .tag {
      display: inline-block;
      background: #ffffff;
      border: 1px solid #ff0000;
      border-radius: 3px;
      line-height: 10px;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ff0000;

      padding: 2px 5px;
    }
  }

  .tips {
    line-height: 10px;
    font-size: 10px;
    color: #e7ac58;
  }

}
.goodsItem {

  padding: 12px 25px;
  background: #fff;

  .name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div:first-child {
      font-size: 12px;
      color: #333333;
      line-height: 15px;
      width: 192px;
    }

    .state {
      line-height: 15px;
      font-size: 12px;
      color: #de4547;

      &.black {
        color: #333333;
      }
    }
  }
}

.goodsInfo {
  display: flex;
  align-items: center;
  margin-top: 9px;

  &.multiple {
    img {
      margin-right: 5px;
    }
  }

  img {
    width: 95px;
    height: 80px;
    border-radius: 5px;
    min-width: 95px;
    margin-right: 21px;
  }

  .right {
    .price {
      span {
        line-height: 1;
        font-weight: bold;
        color: #d53a26;

        &:last-child {
          font-size: 15px;
          margin-left: 3px;
        }

        &:first-child {
          font-size: 12px;
        }
      }
    }

    .sku {
      background: #eeeeee;
      border-radius: 2px;

      padding: 5px 10px;
      line-height: 10px;
      font-size: 10px;
      color: #666666;

      display: inline-block;
    }

    .tagList {
      margin: 7px 0;
      font-size: 0;

      .tag {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #ff0000;
        border-radius: 3px;
        line-height: 10px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ff0000;

        padding: 2px 5px;
      }
    }

    .time {
      line-height: 10px;
      font-size: 10px;
      color: #e7ac58;
    }
  }
}


.priceInfo {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  padding: 12px 0;
  text-align: center;

  span {
    color: #D53A26;
    font-size: 500;
  }

  .price {
    font-size: 15px;
    margin-left: 2px;
  }
}


.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 18px;
  margin-bottom: 10px;
  background-color: #fff;

  .left, .right {
    font-size: 14px;
    color: #333333;
    text-align: right;
    span {
      color: #E01212;
    }


    .placeholder {
      color: #999;
      display: inline-block;
      vertical-align: middle;
    }

    .value {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      width: 9px;
      height: 15px;
      display: inline-block;
      margin-left: 12px;
      vertical-align: middle;
    }
  }
}

.tips {
  font-size: 12px;
  color: #FF0000;
  line-height: 18px;
  margin-top: 12px;
  padding: 0 18px;
}

::v-deep .van-action-sheet__close {
  top: 8px;
}

::v-deep .van-action-sheet__header {
  padding: 8px 0;
}

::v-deep .van-cell-group::after {
  display: none;
}
</style>
